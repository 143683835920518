import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';

import { BronService } from '../services/bron.service';
import { Bron } from '../models/bron';

@Component({
  selector: 'app-bron',
  templateUrl: './bron.component.html',
  styleUrls: ['./bron.component.scss']
})
export class BronComponent implements OnInit {

  bron: Bron = new Bron();
  theForm: FormGroup = this.getForm();

  constructor(
    private bronSvc: BronService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id: number = +params.get('bronId');
      if (id > 0) {
        this.bronSvc.get(id).subscribe(b => {
          this.bron = b;
          this.theForm = this.getForm();
        });
      }
    });
  }

  getForm(): FormGroup {
    if (this.bron) {
      return this.fb.group({
        bronId: [this.bron.bronId],
        naam: [this.bron.naam],
        tabel: [this.bron.tabel]
      });
    } else {
      return this.fb.group({
        bronId: [''],
        naam: [''],
        tabel: ['']
      });
    }
  }

  public Opslaan() {
    this.bron.naam = this.theForm.value.naam;
    this.bron.tabel = this.theForm.value.tabel;

    this.bronSvc.save(this.bron).subscribe(b => {
      this.theForm.markAsPristine();
      this.theForm.markAsUntouched();
      this.NaarOverzicht();
    },
      error => this.handleError(error)
    );
  }

  public Verwijderen() {
    const akkoord = confirm('Bron verwijderen?');
    if (akkoord) {
      this.bronSvc.delete(this.bron.bronId).subscribe(
        () => {
          this.NaarOverzicht();
        },
        error => this.handleError(error)
      );
    }
  }

  NaarOverzicht() {
    this.router.navigate(['hierarchiebronnen']);
  }

  handleError(error: HttpErrorResponse) {
    this.showToast('Fout bij opslaan: ' + error.message);
  }

  showToast(tekst: string) {
    this.snackBar.open(tekst, null, { duration: 3000 });
  }
}
