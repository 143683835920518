import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { forkJoin } from 'rxjs';

import { Authorisatie } from '../models/authorisatie';
import { Authoriteit } from '../models/authoriteit';
import { Bron } from '../models/bron';
import { BronItem } from '../models/bron-item';

import { AuthorisatieService } from '../services/authorisatie.service';
import { AuthoriteitService } from '../services/authoriteit.service';
import { BronItemService } from '../services/bron-item.service';
import { BronService } from '../services/bron.service';

@Component({
  selector: 'app-autoriseren',
  templateUrl: './autoriseren.component.html',
  styleUrls: ['./autoriseren.component.scss']
})
export class AutoriserenComponent implements OnInit {
  bSelectForm: FormGroup = this.getForm();
  bList: Bron[] = [];
  auths: Authoriteit[] = [];
  items: BronItem[] = []
  itemsFilter: BronItem[];
  itemsFilterText = '';

  rechten: Authorisatie[] = [];
  bronId: number;

  authsVrij: Authoriteit[];
  authsVrijFilter: Authoriteit[];
  authsVrijFilterText = '';
  authsRecht: Authoriteit[];

  isLoading = false;
  isAuthLoading = false;

  @ViewChild('selmetrecht', { static: false }) selectMetRecht: ElementRef<HTMLSelectElement>;
  @ViewChild('selzonderrecht', { static: false }) selectZonderRecht: ElementRef<HTMLSelectElement>;
  @ViewChild('selitems', { static: false }) selectItems: ElementRef<HTMLSelectElement>;

  constructor(
    private fb: FormBuilder,
    private bronSvc: BronService,
    private bronItemSvc: BronItemService,
    private authorisatieSvc: AuthorisatieService,
    private authoriteitSvc: AuthoriteitService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.isLoading = true;
    forkJoin([
      this.authoriteitSvc.load(),
      this.bronSvc.load()
    ]).subscribe(([a, b]) => {
      this.auths = a;
      this.bList = b;
      this.bSelectForm = this.getForm();
      this.bronInit(this.bSelectForm.value.bronId);
      this.bSelectForm.get('bronId').valueChanges.subscribe(id => this.bronInit(id));
      this.isLoading = false;
    });
  }

  getForm(): FormGroup {
    if ((this.bList || []).length > 0) {
      return this.fb.group({
        bronId: [this.bList[0].bronId],
      });
    } else {
      return this.fb.group({
        bronId: [0],
      });
    }
  }

  bronInit(id: number) {
    this.bronId = id;
    this.isAuthLoading = true;
    forkJoin([
      this.bronItemSvc.loadByBron(id),
      this.authorisatieSvc.load(id)
    ]).subscribe(([bi, au]) => {
      this.items = bi.sort((a, b) => a.code > b.code ? 1 : -1);
      this.itemsFilterText = '';
      this.itemsFilter = [].concat(this.items);
      this.rechten = au;
      this.authsRecht = [];
      this.authsVrij = [];
      this.authsVrijFilterText = '';
      this.authsVrijFilter = [];
      this.isAuthLoading = false;
    });
  }

  selectItem(e: Event) {
    const bronItemId = parseInt(this.selectItems.nativeElement.value);
    this.authsRecht = this.auths.filter(a => this.rechten.find(r => r.authoriteitId === a.authoriteitId && r.bronItemId === bronItemId))
      .sort(this.sortFunction);
    this.authsVrij = this.auths.filter(a => !this.authsRecht.find(ar => a.authoriteitId === ar.authoriteitId))
      .sort(this.sortFunction);
    this.authsVrijFilter = [].concat(this.authsVrij);
    this.authsVrijFilterText = '';
  }

  removeRecht() {
    const bronItemId = parseInt(this.selectItems.nativeElement.value);
    const waarden = Array.from(this.selectMetRecht.nativeElement.selectedOptions).map(e => parseInt(e.value));

    // optimistiche aanpak
    this.authsVrij = this.authsVrij.concat(this.authsRecht.filter(av => waarden.includes(av.authoriteitId))).sort(this.sortFunction);
    this.authsRecht = this.authsRecht.filter(av => !waarden.includes(av.authoriteitId)).sort(this.sortFunction);

    const ids = this.rechten
      .filter(r => r.bronItemId === bronItemId && waarden.includes(r.authoriteitId))
      .map(r => r.authorisatieId);

    const acties = ids
      .map(id => this.authorisatieSvc.delete(id));

    forkJoin(acties).subscribe(
      r => { this.rechten = this.rechten.filter(r => !ids.includes(r.authorisatieId)) },
      e => { this.handleError(e); }
    );
  }

  addRecht() {
    const bronItemId = parseInt(this.selectItems.nativeElement.value);
    const waarden = Array.from(this.selectZonderRecht.nativeElement.selectedOptions).map(e => parseInt(e.value));

    // optimistiche aanpak
    this.authsRecht = this.authsRecht.concat(this.authsVrij.filter(av => waarden.includes(av.authoriteitId))).sort(this.sortFunction);
    this.authsVrij = this.authsVrij.filter(av => !waarden.includes(av.authoriteitId)).sort(this.sortFunction);

    const acties = waarden
      .map(w => ({ authorisatieId: 0, bronId: this.bronId, bronItemId: bronItemId, authoriteitId: w } as Authorisatie))
      .map(a => this.authorisatieSvc.save(a));

    forkJoin(acties).subscribe(
      r => { this.rechten = this.rechten.concat(r); },
      e => { this.handleError(e); }
    );
  }

  sortFunction(a: Authoriteit, b: Authoriteit) {
    return ((a.isGroep ? '0' : '1') + a.code) < ((b.isGroep ? '0' : '1') + b.code) ? -1 : 1;
  }

  handleError(error: HttpErrorResponse) {
    this.showToast('Fout bij opslaan: ' + error.message);
    this.bronInit(this.bronId);
  }

  showToast(tekst: string) {
    this.snackBar.open(tekst, null, { duration: 3000 });
  }

  filterNietGeautoriseerd(e) {
    const filter = e.target.value;
    console.log(filter)
    if (filter && filter !== '') {
      this.authsVrijFilter = this.authsVrij.filter(a => (a.code.toLowerCase() + ' ' + a.naam.toLowerCase()).includes(filter.toLowerCase()))
    } else {
      this.authsVrijFilter = [].concat(this.authsVrij);
    }
    Array.from(this.selectZonderRecht.nativeElement.selectedOptions).forEach(e => { e.selected = false })
  }

  filterItems(e) {
    const filter = e.target.value;
    console.log(filter)
    if (filter && filter !== '') {
      this.itemsFilter = this.items.filter(a => (a.code.toLowerCase() + ' ' + a.naam.toLowerCase()).includes(filter.toLowerCase()))
    } else {
      this.itemsFilter = [].concat(this.items);
    }
    Array.from(this.selectItems.nativeElement.selectedOptions).forEach(e => { e.selected = false })
  }
}
