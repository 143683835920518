import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { genericSortingAccessor } from '../shared.module';

import { HierarchieService } from '../services/hierarchie.service';
import { Hierarchie } from '../models/hierarchie';

@Component({
  selector: 'app-hierarchie',
  templateUrl: './hierarchieen.component.html',
  styleUrls: ['./hierarchieen.component.scss']
})
export class HierarchieenComponent implements OnInit {
  dataSource = new MatTableDataSource<Hierarchie>();
  displayedColumns: string[] = ['naam', 'bronNaam', 'startdatum', 'einddatum'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  isLoading = false;

  constructor(
    private hierarchieSvc: HierarchieService,
    private router: Router
  ) { }

  ngOnInit() {
    this.laden();
  }

  laden() {
    this.isLoading = true;
    this.hierarchieSvc.load().subscribe(l => {
      this.dataSource.data = l;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = genericSortingAccessor;
      this.isLoading = false;
    });
  }

  public Details(hierarchie: Hierarchie) {
    this.router.navigate(['hierarchie', { hierarchieId: hierarchie.hierarchieId }]);
  }

  public Nieuw() {
    this.router.navigate(['hierarchie', { hierarchieId: 0 }]);
  }

}
