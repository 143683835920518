import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }
  canActivate() {

    return this.authService.isLoggedInObs().pipe(
      map(e => {
        if (e) {
          return true;
        } else {
          this.router.navigate(['unauthorized']);
        }
      }),
      catchError((err) => {
        this.router.navigate(['unauthorized']);
        return of(false);
      }));
  }
}
