import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Hierarchie } from '../models/hierarchie';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class HierarchieService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  load() {
    return this.http.get<Hierarchie[]>(this.appConfigSvc.getConfig().apiRoot + '/hierarchie');
  }

  get(hierarchieId: number) {
    return this.http.get<Hierarchie>(this.appConfigSvc.getConfig().apiRoot + '/hierarchie/' + hierarchieId);
  }

  delete(hierarchieId: number) {
    return this.http.delete<Hierarchie>(this.appConfigSvc.getConfig().apiRoot + '/hierarchie/' + hierarchieId);
  }

  save(hierarchie: Hierarchie) {
    if ((hierarchie.hierarchieId || 0) === 0) {
      return this.http.post<Hierarchie>(this.appConfigSvc.getConfig().apiRoot + '/hierarchie', hierarchie);
    } else {
      return this.http.put<Hierarchie>(this.appConfigSvc.getConfig().apiRoot + '/hierarchie/' + hierarchie.hierarchieId, hierarchie);
    }
  }
}
