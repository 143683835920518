import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BronItem } from '../models/bron-item';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class BronItemService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  loadByHierarchie(hierarchieId: number) {
    return this.http.get<BronItem[]>(this.appConfigSvc.getConfig().apiRoot + '/bronitem/byhierarchie/' + hierarchieId);
  }

  loadByBron(bronId: number) {
    return this.http.get<BronItem[]>(this.appConfigSvc.getConfig().apiRoot + '/bronitem/bybron/' + bronId);
  }

  get(bronItemId: number) {
    return this.http.get<BronItem>(this.appConfigSvc.getConfig().apiRoot + '/bronitem/' + bronItemId);
  }

  delete(bronItemId: number) {
    return this.http.delete<BronItem>(this.appConfigSvc.getConfig().apiRoot + '/bronitem/' + bronItemId);
  }

  save(bronItem: BronItem) {
    if ((bronItem.bronItemId || 0) === 0) {
      return this.http.post<BronItem>(this.appConfigSvc.getConfig().apiRoot + '/bronitem', bronItem);
    } else {
      return this.http.put<BronItem>(this.appConfigSvc.getConfig().apiRoot + '/bronitem/' + bronItem.bronItemId, bronItem);
    }
  }
}
