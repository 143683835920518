import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Groepslid } from '../models/groepslid';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class GroepslidService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  loadByGebruiker(gebruikerId: number) {
    return this.http.get<Groepslid[]>(this.appConfigSvc.getConfig().apiRoot + '/groepslid/bygebruiker/' + gebruikerId);
  }

  loadByGroep(groepId: number) {
    return this.http.get<Groepslid[]>(this.appConfigSvc.getConfig().apiRoot + '/groepslid/bygroep/' + groepId);
  }


  delete(groepslid: Groepslid) {
    return this.http.delete<Groepslid>(
      this.appConfigSvc.getConfig().apiRoot + '/groepslid/' + groepslid.groepId + '/' + groepslid.gebruikerId
    );
  }

  save(groepslid: Groepslid) {
    return this.http.post<Groepslid>(this.appConfigSvc.getConfig().apiRoot + '/groepslid', groepslid);
  }
}
