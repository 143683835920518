import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Bron } from '../models/bron';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class BronService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  load() {
    return this.http.get<Bron[]>(this.appConfigSvc.getConfig().apiRoot + '/bron');
  }

  get(bronId: number) {
    return this.http.get<Bron>(this.appConfigSvc.getConfig().apiRoot + '/bron/' + bronId);
  }

  delete(bronId: number) {
    return this.http.delete<Bron>(this.appConfigSvc.getConfig().apiRoot + '/bron/' + bronId);
  }

  save(bron: Bron) {
    if ((bron.bronId || 0) === 0) {
      return this.http.post<Bron>(this.appConfigSvc.getConfig().apiRoot + '/bron', bron);
    } else {
      return this.http.put<Bron>(this.appConfigSvc.getConfig().apiRoot + '/bron/' + bron.bronId, bron);
    }
  }
}
