import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, Subscription } from 'rxjs';

import { BronItem } from '../models/bron-item';
import { Structuur } from '../models/structuur';
import { Verbinding } from '../models/verbinding';

import { VerbindingService } from '../services/verbinding.service';

export interface VerbindingBewerkDialogData {
  bronItem: BronItem;
  verbindingen: { verbinding: Verbinding, structuur: Structuur }[];
}

@Component({
  selector: 'app-verbinding-bewerk-dialog',
  templateUrl: './verbinding-bewerk-dialog.component.html',
  styleUrls: ['./verbinding-bewerk-dialog.component.scss']
})
export class VerbindingBewerkDialogComponent {
  theForm: FormGroup = this.getForm();
  dialogData: VerbindingBewerkDialogData;
  subscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VerbindingBewerkDialogData,
    private dialogRef: MatDialogRef<VerbindingBewerkDialogData>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private verbindingSvc: VerbindingService
  ) {
    this.dialogData = Object.assign({}, data);
    this.theForm = this.getForm();
    this.validateTotal(this.theForm.value);
    this.subscription = this.theForm.valueChanges.subscribe(f => { this.validateTotal(f); });
  }

  getForm() {
    if (this.dialogData) {
      return this.fb.group({
        code: [this.dialogData.bronItem.code],
        naam: [this.dialogData.bronItem.naam],
        verbindingen: this.fb.array(
          this.dialogData.verbindingen.map(v => this.fb.group({
            verbindingId: [v.verbinding.verbindingId],
            structuurId: [v.verbinding.structuurId],
            bronItemId: [v.verbinding.bronItemId],
            hierarchieId: [v.verbinding.hierarchieId],
            percentage: [v.verbinding.percentage * 100],
            percentageEdit: new FormControl(v.verbinding.percentage * 100, [
              Validators.required,
              Validators.max(100),
              Validators.min(0)
            ]),
            code: [v.structuur.code],
            naam: [v.structuur.naam]
          }))
        )
      });
    } else {
      return this.fb.group({
        code: [null],
        naam: [null],
        verbindingen: this.fb.array([])
      });
    }
  }

  validateTotal(formvalue) {
    let sum = formvalue.verbindingen.map(v => v.percentageEdit).reduce((s, v) => s + v, 0);
    sum = Math.round((sum + Number.EPSILON) * 100) / 100;
    console.log(sum);
    if (sum !== 100) {
      this.theForm.setErrors({ invalidSum: true });
    }
  }

  SluitDialog() {
    const formVerbindingen: FormArray = this.theForm.controls.verbindingen as FormArray;
    const actions = formVerbindingen.controls
      .filter(ctrl => ctrl.value.percentage !== ctrl.value.percentageEdit)
      .map(ctrl => ({
        verbindingId: ctrl.value.verbindingId,
        hierarchieId: ctrl.value.hierarchieId,
        structuurId: ctrl.value.structuurId,
        bronItemId: ctrl.value.bronItemId,
        percentage: ctrl.value.percentageEdit / 100
      } as Verbinding))
      .map(v => this.verbindingSvc.save(v));

    if (actions.length === 0) {
      this.subscription.unsubscribe();
      this.dialogRef.close(null);
    } else {
      forkJoin(actions).subscribe(results => {
        this.subscription.unsubscribe();
        this.dialogRef.close(results);
      }, error => {
        this.showErrorToast(error, 'Fout bij het opslaan');
      });
    }
  }

  CancelDialog() {
    this.subscription.unsubscribe();
    this.dialogRef.close(null);
  }

  showErrorToast(error: any, melding: string) {

    let tekst = melding;

    if (error.message) {
      tekst += '(' + error.message + ')';
    }

    this.snackBar.open(tekst, null, { duration: 3000 });
  }
}
