import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { SharedModule } from './shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TokenInterceptor } from './services/token.interceptor';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';

import { AppRoutingModule } from './app-routing.module';
import { AppConfigService } from './services/app-config.service';
import { AppComponent } from './app.component';
import { StartComponent } from './start/start.component';
import { BeheerSettingsComponent, AppSettingDialog } from './beheer-settings/beheer-settings.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MomentModule } from 'ngx-moment';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { MY_FORMATS } from './shared.module';
import { HierarchieComponent } from './hierarchie/hierarchie.component';
import { BronComponent } from './bron/bron.component';
import { BronnenComponent } from './bronnen/bronnen.component';
import { HierarchieenComponent } from './hierarchieen/hierarchieen.component';
import { HierarchieBewerkComponent } from './hierarchie-bewerk/hierarchie-bewerk.component';
import { HierarchieBewerkDialogComponent } from './hierarchie-bewerk-dialog/hierarchie-bewerk-dialog.component';
import { VerbindingBewerkDialogComponent } from './verbinding-bewerk-dialog/verbinding-bewerk-dialog.component';
import { GebruikersComponent } from './gebruikers/gebruikers.component';
import { GroepenComponent } from './groepen/groepen.component';
import { AutoriserenComponent } from './autoriseren/autoriseren.component';
import { GroepComponent } from './groep/groep.component';
import { GebruikerComponent } from './gebruiker/gebruiker.component';

export function init_app(appConfig: AppConfigService) {
  return () => appConfig.loadAppConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    BeheerSettingsComponent,
    AppSettingDialog,
    UnauthorizedComponent,
    HierarchieComponent,
    BronComponent,
    BronnenComponent,
    HierarchieenComponent,
    HierarchieBewerkComponent,
    HierarchieBewerkDialogComponent,
    VerbindingBewerkDialogComponent,
    GebruikersComponent,
    GroepenComponent,
    AutoriserenComponent,
    GroepComponent,
    GebruikerComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MomentModule
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      multi: true,
      deps: [AppConfigService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGuardService,
    AuthService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AppSettingDialog,
    HierarchieBewerkDialogComponent,
    VerbindingBewerkDialogComponent
  ]
})

export class AppModule { }