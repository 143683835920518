import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { genericSortingAccessor } from '../shared.module';

import { AuthoriteitService } from '../services/authoriteit.service';
import { Authoriteit } from '../models/authoriteit';

@Component({
  selector: 'app-gebruikers',
  templateUrl: './gebruikers.component.html',
  styleUrls: ['./gebruikers.component.scss']
})
export class GebruikersComponent implements OnInit {
  dataSource = new MatTableDataSource<Authoriteit>();
  displayedColumns: string[] = ['code', 'upn', 'naam'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isLoading = false;

  constructor(
    private authoriteitService: AuthoriteitService,
    private router: Router
  ) { }

  ngOnInit() {
    this.laden();
  }

  laden() {
    this.isLoading = true;
    this.authoriteitService.loadGebruikers().subscribe(l => {
      this.dataSource.data = l;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = genericSortingAccessor;
      this.isLoading = false;
    });
  }

  public Details(authoriteit: Authoriteit) {
    this.router.navigate(['gebruiker', { authoriteitId: authoriteit.authoriteitId }]);
  }

  public Nieuw() {
    this.router.navigate(['gebruiker', { authoriteitId: 0 }]);
  }
}
