import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Structuur } from '../models/structuur';
import { StructuurService } from '../services/structuur.service';

@Component({
  selector: 'app-hierarchie-bewerk-dialog',
  templateUrl: './hierarchie-bewerk-dialog.component.html',
  styleUrls: ['./hierarchie-bewerk-dialog.component.scss']
})
export class HierarchieBewerkDialogComponent {

  theForm: FormGroup = this.getForm();
  structuur: Structuur;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Structuur,
    private dialogRef: MatDialogRef<Structuur>,
    private fb: FormBuilder,
    private structuurSvc: StructuurService,
    private snackBar: MatSnackBar
  ) {
    this.structuur = Object.assign({}, data);
    this.theForm = this.getForm();
  }

  getForm() {
    if (this.structuur) {
      return this.fb.group({
        code: [this.structuur.code],
        naam: [this.structuur.naam]
      });
    } else {
      return this.fb.group({
        code: [null],
        naam: [null]
      });
    }
  }

  SluitDialog() {
    this.structuur.code = this.theForm.value.code;
    this.structuur.naam = this.theForm.value.naam;

    this.structuurSvc.save(this.structuur).subscribe(s => {
      this.structuur.structuurId = s.structuurId;
      this.dialogRef.close(this.structuur);
    },
      error => {
        this.showErrorToast(error, 'Fout bij het opslaan');
      });
  }

  CancelDialog() {
    this.dialogRef.close(null);
  }

  showErrorToast(error: any, melding: string) {

    let tekst = melding;

    if (error.message) {
      tekst += '(' + error.message + ')';
    }

    this.snackBar.open(tekst, null, { duration: 3000 });
  }
}
