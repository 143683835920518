import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Authorisatie } from '../models/authorisatie';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorisatieService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  load(bronId: number) {
    return this.http.get<Authorisatie[]>(this.appConfigSvc.getConfig().apiRoot + '/authorisatie?bronId=' + bronId);
  }

  get(authorisatieId: number) {
    return this.http.get<Authorisatie>(this.appConfigSvc.getConfig().apiRoot + '/authorisatie/' + authorisatieId);
  }

  delete(authorisatieId: number) {
    return this.http.delete<Authorisatie>(
      this.appConfigSvc.getConfig().apiRoot + '/authorisatie/' + authorisatieId
    );
  }

  save(authorisatie: Authorisatie) {
    return this.http.post<Authorisatie>(this.appConfigSvc.getConfig().apiRoot + '/authorisatie', authorisatie);
  }
}
