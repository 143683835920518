import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { genericSortingAccessor } from '../shared.module';

import { BronService } from '../services/bron.service';
import { Bron } from '../models/bron';

@Component({
  selector: 'app-bronnen',
  templateUrl: './bronnen.component.html',
  styleUrls: ['./bronnen.component.scss']
})
export class BronnenComponent implements OnInit {
  dataSource = new MatTableDataSource<Bron>();
  displayedColumns: string[] = ['naam', 'tabel'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  isLoading = false;

  constructor(
    private bronSvc: BronService,
    private router: Router
  ) { }

  ngOnInit() {
    this.laden();
  }

  laden() {
    this.isLoading = true;
    this.bronSvc.load().subscribe(l => {
      this.dataSource.data = l;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = genericSortingAccessor;
      this.isLoading = false;
    });
  }

  public Details(bron: Bron) {
    this.router.navigate(['hierarchiebron', { bronId: bron.bronId }]);
  }

  public NieuweBron() {
    this.router.navigate(['hierarchiebron', { bronId: 0 }]);
  }

}
