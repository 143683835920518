import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthoriteitService } from '../services/authoriteit.service';
import { GroepslidService } from '../services/groepslid.service';

import { Authoriteit } from '../models/authoriteit';
import { Groepslid } from '../models/groepslid';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-groep',
  templateUrl: './groep.component.html',
  styleUrls: ['./groep.component.scss']
})
export class GroepComponent implements OnInit {
  groep: Authoriteit = new Authoriteit();
  gebruikers: Authoriteit[] = [];
  lidmaatschap: Groepslid[] = [];
  gebruikersLid: Authoriteit[] = [];
  gebruikersVrij: Authoriteit[] = [];

  theForm: FormGroup = this.getForm();
  @ViewChild('lidvan', { static: false }) lidSelect: ElementRef<HTMLSelectElement>;
  @ViewChild('nietlidvan', { static: false }) vrijSelect: ElementRef<HTMLSelectElement>;

  constructor(
    private authoriteitService: AuthoriteitService,
    private groepslidService: GroepslidService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.authoriteitService.loadGebruikers().subscribe(g => {
      this.gebruikers = g;
      this.route.paramMap.subscribe(params => {
        const id: number = +params.get('authoriteitId');
        if (id > 0) {
          forkJoin([
            this.authoriteitService.get(id),
            this.groepslidService.loadByGroep(id)
          ]).subscribe(([g, l]) => {
            this.groep = g;
            this.lidmaatschap = l;
            this.gebruikersLid = this.gebruikers.filter(g => l.find(cl => g.authoriteitId === cl.gebruikerId));
            this.gebruikersVrij = this.gebruikers.filter(g => !this.gebruikersLid.find(gl => gl.authoriteitId === g.authoriteitId));
            this.theForm = this.getForm();
          });
        } else {
          this.groep = {
            authoriteitId: 0,
            code: '',
            naam: '',
            upn: '',
            isGroep: true
          };
          this.gebruikersVrij = g;
          this.gebruikersLid = [];
        }
      });
    });
  }

  getForm(): FormGroup {
    if (this.groep) {
      return this.fb.group({
        authoriteitId: [this.groep.authoriteitId],
        code: [this.groep.code],
        naam: [this.groep.naam]
      });
    } else {
      return this.fb.group({
        authoriteitId: [0],
        code: [''],
        naam: ['']
      });
    }
  }

  Opslaan() {
    this.groep.code = this.theForm.value.code;
    this.groep.upn = this.theForm.value.code;
    this.groep.naam = this.theForm.value.naam;

    this.authoriteitService.save(this.groep).subscribe(g => {
      this.groep = g;
      this.OpslaanLidmaatschap();
    },
      error => this.handleError(error)
    );
  }

  OpslaanLidmaatschap() {
    let acties = [];

    const nwGroeplid = this.gebruikersLid.filter(g => !this.lidmaatschap.find(l => l.gebruikerId === g.authoriteitId))
      .map(g => ({
        groepId: this.groep.authoriteitId,
        gebruikerId: g.authoriteitId
      }));
    acties = acties.concat(nwGroeplid.map(l => this.groepslidService.save(l)));

    const delGroepsLid = this.gebruikersVrij.filter(g => this.lidmaatschap.find(l => l.gebruikerId === g.authoriteitId))
      .map(g => ({
        groepId: this.groep.authoriteitId,
        gebruikerId: g.authoriteitId
      }));
    acties = acties.concat(delGroepsLid.map(l => this.groepslidService.delete(l)));

    if (acties.length === 0) {
      this.NaarOverzicht();
    } else {
      forkJoin(acties).subscribe(r => {
        this.NaarOverzicht();
      },
        error => this.handleError(error)
      );
    }
  }

  NaarOverzicht() {
    this.router.navigate(['groepen']);
  }

  handleError(error: HttpErrorResponse) {
    this.showToast('Fout bij opslaan: ' + error.message);
  }

  showToast(tekst: string) {
    this.snackBar.open(tekst, null, { duration: 3000 });
  }

  addGroep() {
    const opties = Array.from(this.vrijSelect.nativeElement.selectedOptions).map(e => parseInt(e.value));
    this.gebruikersLid = this.gebruikersLid.concat(this.gebruikersVrij.filter(g => opties.includes(g.authoriteitId)));
    this.gebruikersVrij = this.gebruikersVrij.filter(g => !opties.includes(g.authoriteitId));
    this.theForm.markAsDirty();
  }

  removeGroep() {
    const opties = Array.from(this.lidSelect.nativeElement.selectedOptions).map(e => parseInt(e.value));
    this.gebruikersVrij = this.gebruikersVrij.concat(this.gebruikersLid.filter(g => opties.includes(g.authoriteitId)));
    this.gebruikersLid = this.gebruikersLid.filter(g => !opties.includes(g.authoriteitId));
    this.theForm.markAsDirty();
  }
}
