import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { Authoriteit } from '../models/authoriteit';
@Injectable({
  providedIn: 'root'
})
export class AuthoriteitService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  load() {
    return this.http.get<Authoriteit[]>(this.appConfigSvc.getConfig().apiRoot + '/authoriteit');
  }

  loadGebruikers() {
    return this.http.get<Authoriteit[]>(this.appConfigSvc.getConfig().apiRoot + '/authoriteit/gebruikers');
  }

  loadGroepen() {
    return this.http.get<Authoriteit[]>(this.appConfigSvc.getConfig().apiRoot + '/authoriteit/groepen');
  }

  get(authoriteitId: number) {
    return this.http.get<Authoriteit>(this.appConfigSvc.getConfig().apiRoot + '/authoriteit/' + authoriteitId);
  }

  delete(authoriteitId: number) {
    return this.http.delete<Authoriteit>(this.appConfigSvc.getConfig().apiRoot + '/authoriteit/' + authoriteitId);
  }

  save(authoriteit: Authoriteit) {
    if ((authoriteit.authoriteitId || 0) === 0) {
      return this.http.post<Authoriteit>(this.appConfigSvc.getConfig().apiRoot + '/authoriteit', authoriteit);
    } else {
      return this.http.put<Authoriteit>(this.appConfigSvc.getConfig().apiRoot + '/authoriteit/' + authoriteit.authoriteitId, authoriteit);
    }
  }
}
