import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartComponent } from './start/start.component';
import { BeheerSettingsComponent } from './beheer-settings/beheer-settings.component';
import { AuthGuardService } from './services/auth-guard.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { HierarchieenComponent } from './hierarchieen/hierarchieen.component';
import { HierarchieComponent } from './hierarchie/hierarchie.component';
import { HierarchieBewerkComponent } from './hierarchie-bewerk/hierarchie-bewerk.component';
import { BronnenComponent } from './bronnen/bronnen.component';
import { BronComponent } from './bron/bron.component';
import { GebruikersComponent } from './gebruikers/gebruikers.component';
import { GroepenComponent } from './groepen/groepen.component';
import { AutoriserenComponent } from './autoriseren/autoriseren.component';
import { GroepComponent } from './groep/groep.component';
import { GebruikerComponent } from './gebruiker/gebruiker.component';

const routes: Routes = [
  { path: '', component: StartComponent, canActivate: [AuthGuardService] },
  { path: 'start', component: StartComponent, canActivate: [AuthGuardService] },
  { path: 'beheersettings', component: BeheerSettingsComponent, canActivate: [AuthGuardService] },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'logout', component: UnauthorizedComponent },
  { path: 'hierarchieen', component: HierarchieenComponent },
  { path: 'hierarchie', component: HierarchieComponent },
  { path: 'hierarchiebronnen', component: BronnenComponent },
  { path: 'hierarchiebron', component: BronComponent },
  { path: 'hierarchiebewerk', component: HierarchieBewerkComponent },
  { path: 'gebruikers', component: GebruikersComponent },
  { path: 'groepen', component: GroepenComponent },
  { path: 'autoriseren', component: AutoriserenComponent },
  { path: 'gebruiker', component: GebruikerComponent },
  { path: 'groep', component: GroepComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
