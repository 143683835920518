import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Structuur } from '../models/structuur';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class StructuurService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  load(hierarchieId: number) {
    return this.http.get<Structuur[]>(this.appConfigSvc.getConfig().apiRoot + '/structuur?hierarchieId=' + hierarchieId);
  }

  get(structuurId: number) {
    return this.http.get<Structuur>(this.appConfigSvc.getConfig().apiRoot + '/structuur/' + structuurId);
  }

  delete(structuurId: number) {
    return this.http.delete<Structuur>(this.appConfigSvc.getConfig().apiRoot + '/structuur/' + structuurId);
  }

  save(structuur: Structuur) {
    if ((structuur.structuurId || 0) === 0) {
      return this.http.post<Structuur>(this.appConfigSvc.getConfig().apiRoot + '/structuur', structuur);
    } else {
      return this.http.put<Structuur>(this.appConfigSvc.getConfig().apiRoot + '/structuur/' + structuur.structuurId, structuur);
    }
  }
}
