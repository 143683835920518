import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Verbinding } from '../models/verbinding';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class VerbindingService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  load(hierarchieId: number) {
    return this.http.get<Verbinding[]>(this.appConfigSvc.getConfig().apiRoot + '/verbinding?hierarchieId=' + hierarchieId);
  }

  get(verbindingId: number) {
    return this.http.get<Verbinding>(this.appConfigSvc.getConfig().apiRoot + '/verbinding/' + verbindingId);
  }

  delete(verbindingId: number) {
    return this.http.delete<Verbinding>(this.appConfigSvc.getConfig().apiRoot + '/verbinding/' + verbindingId);
  }

  save(verbinding: Verbinding) {
    if ((verbinding.verbindingId || 0) === 0) {
      return this.http.post<Verbinding>(this.appConfigSvc.getConfig().apiRoot + '/verbinding', verbinding);
    } else {
      return this.http.put<Verbinding>(this.appConfigSvc.getConfig().apiRoot + '/verbinding/' + verbinding.verbindingId, verbinding);
    }
  }
}
