import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { AppConfigService } from './app-config.service';
import { AppConfig } from '../models/app-config';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

export { User };

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private static _userManager: UserManager;
  private user: User;

  constructor(appConfigSvc: AppConfigService) {

    if (!AuthService._userManager) {

      var appConfig = appConfigSvc.getConfig();

      var settings = {
        authority: appConfig.oidcAuthority,
        client_id: appConfig.oidcClientId,
        redirect_uri: appConfig.oidcRedirectUri,
        silent_redirect_uri: appConfig.oidcSilentRedirectUri,
        post_logout_redirect_uri: appConfig.oidcPostLogoutRedirectUri,
        response_type: 'id_token token',
        scope: appConfig.oidcScopes,
        automaticSilentRenew: true
      };

      AuthService._userManager = new UserManager(settings);

    } else {
      console.log('usermanager al geladen');
    }

  }


  getUser(): Observable<User> {
    return from(AuthService._userManager.getUser());
  }

  isLoggedInObs(): Observable<boolean> {
    return from(AuthService._userManager.getUser()).pipe(map<User, boolean>((user) => {
      if (user) {
        return true;
      } else {
        return false;
      }
    }));
  }

  public login(): Promise<void> {
    return AuthService._userManager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return AuthService._userManager.signinSilent().catch((err) => {

      console.log('Failed silent renewal of token');
      console.log(err);

      AuthService._userManager.signinRedirect();
      return null;
    });
  }

  public logout(): Promise<void> {
    return AuthService._userManager.signoutRedirect();
  }

}
