import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService, User } from '../services/auth.service';


@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  currentUser: User;

  constructor(
    private location: Location,
    private authService: AuthService) {
    this.authService.getUser().subscribe(u => { this.currentUser = u; });
  }

  ngOnInit() {
  }

  public isIngelogd(): boolean {
    if (!!this.currentUser && !!this.currentUser.access_token) {
      return true;
    } else {
      return false;
    }
  }

  public naam(): string {
    if (!!this.currentUser && !!this.currentUser.profile && !!this.currentUser.profile['name']) {
      return this.currentUser.profile['name'];
    } else {
      return 'Onbekend';
    }
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

}
