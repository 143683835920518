import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User } from './services/auth.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { AppConfigService } from './services/app-config.service';
import { AppInfoService } from './services/app-info.service';
import { AppInfo } from './models/app-info';
import { MenuItem } from './models/menu-item';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppInsights } from 'applicationinsights-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  watcher: Subscription;
  activeMediaQuery = '';
  appComponentMenuIsOpen = true;
  currentUser: User;
  drawerMode = 'side';


  appInfo: AppInfo = {
    username: null,
    versionNumber: '1.0.0.0',
    menuItems: [],
    isAdmin: null,
    isUser: null
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private media: MediaObserver,
    private iconRegistry: MatIconRegistry,
    private appConfigSvc: AppConfigService,
    private appInfoSvc: AppInfoService,
    private sanitizer: DomSanitizer
  ) {

    this.watcher = media.media$.subscribe((change: MediaChange) => {
      this.activeMediaQuery = change ? change.mqAlias : '';
      if (change.mqAlias === 'xs' || change.mqAlias === 'sm') {
        this.appComponentMenuIsOpen = false;
        this.drawerMode = 'over';
      } else {
        this.appComponentMenuIsOpen = true;
        this.drawerMode = 'side';
      }
    });

    if (appConfigSvc.getConfig().applicationInsightsKey &&
      appConfigSvc.getConfig().applicationInsightsKey !== '') {
      AppInsights.downloadAndSetup({ instrumentationKey: appConfigSvc.getConfig().applicationInsightsKey });
    }

    this.setupIconRegistry();

  }

  ngOnInit() {

    this.authService.getUser().subscribe(u => {
      this.currentUser = u;
      this.appInfoSvc.get().subscribe(a => this.appInfo = a);

    });

  }

  ngOnDestroy() {
    this.watcher.unsubscribe();
  }

  setupIconRegistry() {

    this.iconRegistry.addSvgIcon('lan_connect', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/lan-connect.svg'));
    this.iconRegistry.addSvgIcon('lan_disconnect', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/lan-disconnect.svg'));
    this.iconRegistry.addSvgIcon('lan_pending', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/lan-pending.svg'));

  }

  login() {
    this.authService.login();
  }

  public DrawerMode(): string {
    return this.drawerMode;
  }

  public navigate(pad: string) {

	if (pad && pad.startsWith('http')) {
      window.location.href = pad;
    }
	
    if (this.activeMediaQuery === 'xs' || this.activeMediaQuery === 'sm') {
      this.appComponentMenuIsOpen = false;
    }

    this.router.navigateByUrl(pad);
  }

  public naam(): string {
    if (!!this.currentUser && !!this.currentUser.profile && !!this.currentUser.profile['name']) {
      return this.currentUser.profile['name'];
    } else {
      return 'Onbekend';
    }
  }

  public menuIsOpen(): boolean {

    return this.appComponentMenuIsOpen;
  }

  public toggleMenu() {
    this.appComponentMenuIsOpen = !this.appComponentMenuIsOpen;
  }

  public toggleChildren(item: MenuItem) {
    item.childrenVisible = !item.childrenVisible;
  }

}

