import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, of as observableOf } from 'rxjs';

import { Hierarchie } from '../models/hierarchie';
import { Structuur } from '../models/structuur';
import { Verbinding } from '../models/verbinding';
import { BronItem } from '../models/bron-item';

import { HierarchieService } from '../services/hierarchie.service';
import { StructuurService } from '../services/structuur.service';
import { VerbindingService } from '../services/verbinding.service';
import { BronItemService } from '../services/bron-item.service';
import { HierarchieBewerkDialogComponent } from '../hierarchie-bewerk-dialog/hierarchie-bewerk-dialog.component';
import {
  VerbindingBewerkDialogComponent,
  VerbindingBewerkDialogData
} from '../verbinding-bewerk-dialog/verbinding-bewerk-dialog.component';

interface HierarchieStructuur {
  isStructuur: boolean;
  structuurId: number;
  bronItemId: number;
  verbindingId: number;
  hierarchieId: number;
  ouderId: number;
  code: string;
  naam: string;
  children: HierarchieStructuur[];
  parent: HierarchieStructuur;
}

interface HierachieBronitem {
  bronItemId: number;
  bronId: number;
  code: string;
  naam: string;
  isSelected: boolean;
  isVerbonden: boolean;
}

@Component({
  selector: 'app-hierarchie-bewerk',
  templateUrl: './hierarchie-bewerk.component.html',
  styleUrls: ['./hierarchie-bewerk.component.scss']
})
export class HierarchieBewerkComponent implements OnInit {

  hSelectForm: FormGroup = this.getForm();
  hierarchie: Hierarchie;
  hList: Hierarchie[] = [];
  hStructuur: Structuur[] = [];
  hTreeControl: NestedTreeControl<HierarchieStructuur>;
  hDataSource: MatTreeNestedDataSource<HierarchieStructuur>;

  hBronitems: HierachieBronitem[] = [];
  hVerbindingen: Verbinding[] = [];

  isLoading = false;
  isHierarchieLoading = false;
  hideVerbonden = true;

  constructor(
    private fb: FormBuilder,
    private hierarchieSvc: HierarchieService,
    private structuurSvc: StructuurService,
    private bronItemSvc: BronItemService,
    private verbindingSvc: VerbindingService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.hierarchieSvc.load().subscribe(h => {
      this.hList = h;
      this.hSelectForm = this.getForm();
      this.isLoading = false;
      this.hierInit(this.hSelectForm.value.hierarchieId);
      this.hSelectForm.get('hierarchieId').valueChanges.subscribe(id => this.hierInit(id));
    });
  }

  getForm(): FormGroup {
    if ((this.hList || []).length > 0) {
      return this.fb.group({
        hierarchieId: [this.hList[0].hierarchieId],
      });
    } else {
      return this.fb.group({
        hierarchieId: [0],
      });
    }
  }

  hierInit(id: number) {
    this.hierarchie = this.hList.find(h => h.hierarchieId === id);
    this.isHierarchieLoading = true;

    forkJoin([
      this.structuurSvc.load(id),
      this.verbindingSvc.load(id),
      this.bronItemSvc.loadByHierarchie(id)
    ]).subscribe(([s, v, b]) => {
      this.hStructuur = s;
      this.hVerbindingen = v;
      this.hBronitems = b.map(bi => {
        return {
          bronItemId: bi.bronItemId,
          bronId: bi.bronId,
          code: bi.code,
          naam: bi.naam,
          isSelected: false,
          isVerbonden: (this.hVerbindingen.filter(cv => cv.bronItemId === bi.bronItemId).length > 0)
        };
      }).sort((a,b) => a.code > b.code ? 1 : -1);

      this.hTreeControl = new NestedTreeControl<HierarchieStructuur>(this.hierGetChildren);
      this.hDataSource = new MatTreeNestedDataSource();
      this.hDataSource.data = this.hierFindChildren(this.hStructuur, 0, null);

      this.hVerbindingen = v;

      this.isHierarchieLoading = false;
    });
  }

  hierFindChildren = (structuur: Structuur[], id: number, parent): HierarchieStructuur[] =>
    structuur
      .filter(s => s.ouderId === id)
      .map(s => {
        const newNode: HierarchieStructuur = this.hierarchieStructuurFromStructuur(s);
        newNode.parent = parent;
        newNode.children = this.hierFindChildren(structuur, s.structuurId, newNode);

        newNode.children = newNode.children.concat(
          this.hVerbindingen
            .filter(v => v.structuurId === s.structuurId)
            .map(v => {
              const bi = this.hBronitems.find(b => v.bronItemId === b.bronItemId);
              const st = this.hierarchieStructuurFromBronItem(bi, v.verbindingId);
              st.parent = newNode;
              st.ouderId = id;
              return st;
            })
            .sort((a, b) => a.code > b.code ? 1 : -1)
        );

        return newNode;
      })
      .sort((a, b) => a.code > b.code ? 1 : -1)

  hierGetChildren = (node: HierarchieStructuur) => observableOf(node.children);
  hierHasNestedChild = (_: number, nodeData: HierarchieStructuur) => nodeData.children.length > 0;

  hierRefresh() {
    const data = this.hDataSource.data;
    this.hDataSource.data = null;
    this.hDataSource.data = data;
  }

  hierDeleteNode(e: HierarchieStructuur) {
    if (e.isStructuur) {
      this.hierDeleteStructuurNode(e);
    } else {
      this.hierDeleteBronNode(e);
    }
  }

  hierDeleteStructuurNode(e: HierarchieStructuur) {
    if (window.confirm('Weet u zeker dat u ' + e.naam + ' wilt verwijderen?')) {
      this.structuurSvc.delete(e.structuurId).subscribe((ds: Structuur) => {
        this.hStructuur = this.hStructuur.filter(c => c.structuurId !== ds.structuurId);
        if (e.parent) {
          e.parent.children = e.parent.children
            .filter(c => c.structuurId !== e.structuurId)
            .sort((a, b) => a.code > b.code ? 1 : -1);
          this.hierRefresh();
        }
      });
    }
  }

  hierDeleteBronNode(e: HierarchieStructuur) {
    this.verbindingSvc.delete(e.verbindingId).subscribe(v => {
      this.hVerbindingen = this.hVerbindingen.filter(c => c.verbindingId !== v.verbindingId);
      if (e.parent) {
        e.parent.children = e.parent.children
          .filter(c => c.bronItemId !== e.bronItemId)
          .sort((a, b) => a.code > b.code ? 1 : -1);
        this.hierRefresh();
      }
      const bi = this.hBronitems.find(b => b.bronItemId === e.bronItemId);
      bi.isVerbonden = (this.hVerbindingen.filter(cv => cv.bronItemId === bi.bronItemId).length > 0);
    });
  }

  hierEditNode(e: HierarchieStructuur) {
    if (e.isStructuur) {
      this.hierEditStructuurNode(e);
    } else {
      this.hierEditBronNode(e);
    }
  }

  hierEditStructuurNode(e: HierarchieStructuur) {
    const edit = this.structuurFromHierarchieStructuur(e);

    const dialogRef = this.dialog.open(HierarchieBewerkDialogComponent, { data: edit });

    dialogRef.afterClosed().subscribe((s: Structuur) => {
      if (s) {
        e.code = s.code;
        e.naam = s.naam;

        e.parent.children = e.parent.children
          .sort((a, b) => a.code > b.code ? 1 : -1);
      }
    });
  }

  hierEditBronNode(e: HierarchieStructuur) {
    const data: VerbindingBewerkDialogData = {
      bronItem: this.hBronitems.find(b => b.bronItemId === e.bronItemId),
      verbindingen: this.hVerbindingen
        .filter(v => v.bronItemId === e.bronItemId)
        .map(v => ({
          verbinding: v,
          structuur: this.hStructuur.find(s => s.structuurId === v.structuurId)
        }))
    };

    const dialogRef = this.dialog.open(VerbindingBewerkDialogComponent, { data });

    dialogRef.afterClosed().subscribe((results: Verbinding[]) => {
      if (results) {
        results.forEach(v => {
          this.hVerbindingen.find(vc => vc.verbindingId === v.verbindingId).percentage = v.percentage;
        });
      }
    });
  }

  hierAddNode(e: HierarchieStructuur) {
    const nw: Structuur = {
      structuurId: 0,
      hierarchieId: e.hierarchieId,
      ouderId: e.structuurId,
      code: '',
      naam: ''
    };

    const dialogRef = this.dialog.open(HierarchieBewerkDialogComponent, { data: nw });

    dialogRef.afterClosed().subscribe((s: Structuur) => {
      const hs = this.hierarchieStructuurFromStructuur(s);
      hs.parent = e;
      e.children = e.children
        .concat(hs)
        .sort((a, b) => a.code > b.code ? 1 : -1);
      this.hierRefresh();
    });
  }

  hierAddRootNode() {
    const nw: Structuur = {
      structuurId: 0,
      hierarchieId: this.hSelectForm.value.hierarchieId,
      ouderId: 0,
      code: '',
      naam: ''
    };

    const dialogRef = this.dialog.open(HierarchieBewerkDialogComponent, { data: nw });

    dialogRef.afterClosed().subscribe((s: Structuur) => {
      const hs = this.hierarchieStructuurFromStructuur(s);
      this.hDataSource.data = this.hDataSource.data
        .concat(hs)
        .sort((a, b) => a.code > b.code ? 1 : -1);
      this.hierRefresh();
    });
  }

  hierAddBronitems(s: HierarchieStructuur) {
    const actions = this.hBronitems
      .filter(b => b.isSelected)
      .filter(b => !s.children.find(st => st.bronItemId === b.bronItemId))
      .map(b => ({
        verbindingId: 0,
        structuurId: s.structuurId,
        hierarchieId: s.hierarchieId,
        bronItemId: b.bronItemId,
        percentage: 1
      } as Verbinding))
      .map(v => this.verbindingSvc.save(v));

    forkJoin(actions).subscribe(results => {
      this.hVerbindingen = this.hVerbindingen.concat(results);
      s.children = s.children
        .concat(results.map(v => {
          const bi = this.hBronitems.find(b => b.bronItemId === v.bronItemId);
          const st = this.hierarchieStructuurFromBronItem(bi, v.verbindingId);
          st.parent = s;
          st.ouderId = s.structuurId;
          return st;
        }))
        .sort((a, b) => a.code > b.code ? 1 : -1);

      this.hBronitems.filter(b => b.isSelected).forEach(b => {
        b.isSelected = false;
        b.isVerbonden = (this.hVerbindingen.filter(cv => cv.bronItemId === b.bronItemId).length > 0);
      });

      this.hierRefresh();
    });

  }

  bronItemEdit(bi: HierachieBronitem) {
    const data: VerbindingBewerkDialogData = {
      bronItem: bi,
      verbindingen: this.hVerbindingen
        .filter(v => v.bronItemId === bi.bronItemId)
        .map(v => ({
          verbinding: v,
          structuur: this.hStructuur.find(s => s.structuurId === v.structuurId)
        }))
    };

    const dialogRef = this.dialog.open(VerbindingBewerkDialogComponent, { data });

    dialogRef.afterClosed().subscribe((results: Verbinding[]) => {
      if (results) {
        results.forEach(v => {
          this.hVerbindingen.find(vc => vc.verbindingId === v.verbindingId).percentage = v.percentage;
        });
      }
    });
  }

  structuurFromHierarchieStructuur(hs: HierarchieStructuur) {
    const s: Structuur = {
      structuurId: hs.structuurId,
      hierarchieId: hs.hierarchieId,
      ouderId: hs.ouderId,
      code: hs.code,
      naam: hs.naam
    };
    return s;
  }

  hierarchieStructuurFromStructuur(s: Structuur) {
    const hs: HierarchieStructuur = {
      isStructuur: true,
      structuurId: s.structuurId,
      bronItemId: 0,
      verbindingId: 0,
      hierarchieId: s.hierarchieId,
      ouderId: s.ouderId,
      code: s.code,
      naam: s.naam,
      children: [],
      parent: null
    };

    return hs;
  }

  hierarchieStructuurFromBronItem(b: BronItem, verbindingId: number) {
    const hs: HierarchieStructuur = {
      isStructuur: false,
      structuurId: 0,
      bronItemId: b.bronItemId,
      verbindingId: verbindingId,
      hierarchieId: 0,
      ouderId: 0,
      code: b.code,
      naam: b.naam,
      children: [],
      parent: null
    };

    return hs;
  }
}
