import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';

import { HierarchieService } from '../services/hierarchie.service';
import { Hierarchie } from '../models/hierarchie';
import { BronService } from '../services/bron.service';
import { Bron } from '../models/bron';

@Component({
  selector: 'app-bron',
  templateUrl: './hierarchie.component.html',
  styleUrls: ['./hierarchie.component.scss']
})
export class HierarchieComponent implements OnInit {

  hierarchie: Hierarchie = new Hierarchie();
  theForm: FormGroup = this.getForm();
  bronnen: Bron[] = [];

  constructor(
    private hierarchieSvc: HierarchieService,
    private bronSvc: BronService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }


  ngOnInit() {
    this.bronSvc.load().subscribe(b => { this.bronnen = b; });

    this.route.paramMap.subscribe(params => {
      const id: number = +params.get('hierarchieId');
      if (id > 0) {
        this.hierarchieSvc.get(id).subscribe(b => {
          this.hierarchie = b;
          this.theForm = this.getForm();
        });
      }
    });
  }

  getForm(): FormGroup {
    if (this.hierarchie) {
      return this.fb.group({
        hierarchieId: [this.hierarchie.hierarchieId],
        naam: [this.hierarchie.naam],
        bronId: [this.hierarchie.bronId],
        startdatum: [this.hierarchie.startdatum],
        einddatum: [this.hierarchie.einddatum]
      });
    } else {
      return this.fb.group({
        hierarchieId: [''],
        naam: [''],
        bronId: [''],
        startdatum: [null],
        einddatum: [null]
      });
    }
  }

  public Opslaan() {
    this.hierarchie.naam = this.theForm.value.naam;
    this.hierarchie.bronId = this.theForm.value.bronId;
    this.hierarchie.startdatum = this.momentToDate(this.theForm.value.startdatum);
    this.hierarchie.einddatum = this.momentToDate(this.theForm.value.einddatum);

    this.hierarchieSvc.save(this.hierarchie).subscribe(b => {
      this.theForm.markAsPristine();
      this.theForm.markAsUntouched();
      this.NaarOverzicht();
    },
      error => this.handleError(error)
    );
  }

  public Verwijderen() {
    const akkoord = confirm('Hierarchie verwijderen?');
    if (akkoord) {
      this.hierarchieSvc.delete(this.hierarchie.hierarchieId).subscribe(
        () => {
          this.NaarOverzicht();
        },
        error => this.handleError(error)
      );
    }
  }

  NaarOverzicht() {
    this.router.navigate(['hierarchieen']);
  }

  handleError(error: HttpErrorResponse) {
    this.showToast('Fout bij opslaan: ' + error.message);
  }

  showToast(tekst: string) {
    this.snackBar.open(tekst, null, { duration: 3000 });
  }

  momentToDate(d) {
    let r: Date = d;
    if (d.toDate) {
      r = d.toDate();
    } else {
      r = new Date(d);
    }

    return new Date(r.getFullYear(), r.getMonth(), r.getDate(), 12, 0, 0);
  }
}
