import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthoriteitService } from '../services/authoriteit.service';
import { GroepslidService } from '../services/groepslid.service';

import { Authoriteit } from '../models/authoriteit';
import { Groepslid } from '../models/groepslid';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-gebruiker',
  templateUrl: './gebruiker.component.html',
  styleUrls: ['./gebruiker.component.scss']
})
export class GebruikerComponent implements OnInit {
  gebruiker: Authoriteit = new Authoriteit();
  groepen: Authoriteit[] = [];
  lidmaatschap: Groepslid[] = [];
  groepenLid: Authoriteit[] = [];
  groepenVrij: Authoriteit[] = [];
  theForm: FormGroup = this.getForm();

  @ViewChild('lidvan', { static: false }) lidSelect: ElementRef<HTMLSelectElement>;
  @ViewChild('nietlidvan', { static: false }) vrijSelect: ElementRef<HTMLSelectElement>;

  constructor(
    private authoriteitService: AuthoriteitService,
    private groepslidService: GroepslidService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.authoriteitService.loadGroepen().subscribe(g => {
      this.groepen = g;
      this.route.paramMap.subscribe(params => {
        const id: number = +params.get('authoriteitId');
        if (id > 0) {
          forkJoin([
            this.authoriteitService.get(id),
            this.groepslidService.loadByGebruiker(id)
          ]).subscribe(([g, l]) => {
            this.gebruiker = g;
            this.lidmaatschap = l;
            this.groepenLid = this.groepen.filter(g => l.find(cl => g.authoriteitId === cl.groepId));
            this.groepenVrij = this.groepen.filter(g => !this.groepenLid.find(gl => gl.authoriteitId === g.authoriteitId));
            this.theForm = this.getForm();
          });
        } else {
          this.gebruiker = {
            authoriteitId: 0,
            code: '',
            naam: '',
            upn: '',
            isGroep: false
          };
          this.lidmaatschap = [];
          this.groepenVrij = g;
          this.groepenLid = [];
        }
      });
    });
  }

  getForm(): FormGroup {
    if (this.gebruiker) {
      return this.fb.group({
        authoriteitId: [this.gebruiker.authoriteitId],
        code: [this.gebruiker.code],
        upn: [this.gebruiker.upn],
        naam: [this.gebruiker.naam]
      });
    } else {
      return this.fb.group({
        authoriteitId: [0],
        code: [''],
        naam: ['']
      });
    }
  }

  Opslaan() {
    this.gebruiker.naam = this.theForm.value.naam;
    this.gebruiker.code = this.theForm.value.code;
    this.gebruiker.upn = this.theForm.value.upn;

    this.authoriteitService.save(this.gebruiker).subscribe(g => {
      this.gebruiker = g;
      this.OpslaanLidmaatschap();
    },
      error => this.handleError(error)
    );

  }

  OpslaanLidmaatschap() {
    let acties = [];

    const nwGroeplid = this.groepenLid.filter(g => !this.lidmaatschap.find(l => l.groepId === g.authoriteitId))
      .map(g => ({
        groepId: g.authoriteitId,
        gebruikerId: this.gebruiker.authoriteitId
      }));
    acties = acties.concat(nwGroeplid.map(l => this.groepslidService.save(l)));

    const delGroepsLid = this.groepenVrij.filter(g => this.lidmaatschap.find(l => l.groepId === g.authoriteitId))
      .map(g => ({
        groepId: g.authoriteitId,
        gebruikerId: this.gebruiker.authoriteitId
      }));
    acties = acties.concat(delGroepsLid.map(l => this.groepslidService.delete(l)));

    if (acties.length === 0) {
      this.NaarOverzicht();
    } else {
      forkJoin(acties).subscribe(r => {
        this.NaarOverzicht();
      },
        error => this.handleError(error)
      );
    }

  }

  NaarOverzicht() {
    this.router.navigate(['gebruikers']);
  }

  handleError(error: HttpErrorResponse) {
    this.showToast('Fout bij opslaan: ' + error.message);
  }

  showToast(tekst: string) {
    this.snackBar.open(tekst, null, { duration: 3000 });
  }

  addGroep() {
    const opties = Array.from(this.vrijSelect.nativeElement.selectedOptions).map(e => parseInt(e.value));
    this.groepenLid = this.groepenLid.concat(this.groepenVrij.filter(g => opties.includes(g.authoriteitId)));
    this.groepenVrij = this.groepenVrij.filter(g => !opties.includes(g.authoriteitId));
    this.theForm.markAsDirty();
  }

  removeGroep() {
    const opties = Array.from(this.lidSelect.nativeElement.selectedOptions).map(e => parseInt(e.value));
    this.groepenVrij = this.groepenVrij.concat(this.groepenLid.filter(g => opties.includes(g.authoriteitId)));
    this.groepenLid = this.groepenLid.filter(g => !opties.includes(g.authoriteitId));
    this.theForm.markAsDirty();
  }

}
