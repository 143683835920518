import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { genericSortingAccessor } from '../shared.module';

import { AuthoriteitService } from '../services/authoriteit.service';
import { Authoriteit } from '../models/authoriteit';

@Component({
  selector: 'app-groepen',
  templateUrl: './groepen.component.html',
  styleUrls: ['./groepen.component.scss']
})
export class GroepenComponent implements OnInit {
  dataSource = new MatTableDataSource<Authoriteit>();
  displayedColumns: string[] = ['delete', 'code', 'naam'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isLoading = false;

  constructor(
    private authoriteitService: AuthoriteitService,
    private router: Router
  ) { }

  ngOnInit() {
    this.laden();
  }

  laden() {
    this.isLoading = true;
    this.authoriteitService.loadGroepen().subscribe(l => {
      this.dataSource.data = l;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = genericSortingAccessor;
      this.isLoading = false;
    });
  }

  public Details(authoriteit: Authoriteit) {
    this.router.navigate(['groep', { authoriteitId: authoriteit.authoriteitId }]);
  }

  public Delete(authoriteit: Authoriteit, e: MouseEvent) {
    e.stopPropagation();
    if (confirm('Weet u zeker dat u groep ' + authoriteit.naam + ' wilt verwijderen?')) {
      this.dataSource.data = this.dataSource.data.filter(d => d.authoriteitId !== authoriteit.authoriteitId);
      this.authoriteitService.delete(authoriteit.authoriteitId).subscribe(() => {
        this.laden();
      });
    }
  }

  public Nieuw() {
    this.router.navigate(['groep', { authoriteitId: 0 }]);
  }

}
